/*!
 * Winperks v1.0.0
 * Copyright (C) Winperks, 2024. All Rights Reserved.
 *
 * This source code and all associated documentation files (the "Software")
 * are the proprietary property of Winperks and are protected throughout
 * the world.
 * Unauthorized copying, distribution, modification, or any other use of
 * this Software, in whole or in part, is strictly prohibited.
 *
 * This Software is confidential and intended solely for the use of
 * authorized personnel of Winperks or its affiliates.
 * Unauthorized access, reproduction, disclosure, or use of this source code
 * is forbidden.
 *
 * If you have received this file in error, please notify Winperks immediately
 * and permanently delete the file.
 */

import React from "react";
import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Home,
  Predictions,
  About,
  SignUp,
  Help,
  Error,
  LogIn,
  ProtectedRoute,
  Terms,
  Privacy,
} from "./pages";
import { EmailVerify, ForgotPassword, PasswordReset } from "./components";
import {
  SharedLayout,
  PredictionShop,
  MyPredictions,
  Profile,
  Stats,
} from "./pages/dashboard";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Unsubscribe from "./components/Unsubscribe/Unsubscribe";

function App() {
  const intialOptions = {
    // clientId:
    //   "AfUNe09zRhNQIZRiU3DikTRugjM_kR2ogH41mxtfcfuSgMG86o3VLWOA2erAc6lyCuztARybNERIXLAt",
    clientId:
      "AY0kYM7inY3x7ad6uP9wgKcTEjQHOp8ZStQLXEFK3wwChyeE4LB39IXvozKoMVXkQ8UdDA_wVTPth_LQ",
    currency: "USD",
    intent: "capture",
  };
  return (
    <React.StrictMode>
      <PayPalScriptProvider options={intialOptions}>
        <BrowserRouter>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <ProtectedRoute>
                  <SharedLayout />
                </ProtectedRoute>
              }
            >
              <Route exact index element={<Stats />}></Route>
              <Route path="my-predictions" element={<MyPredictions />}></Route>
              <Route
                path="prediction-shop"
                element={<PredictionShop />}
              ></Route>
              <Route path="profile" element={<Profile />}></Route>
            </Route>
            <Route path="/home" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/predictions" element={<Predictions />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<LogIn />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />

            <Route path="/help" element={<Help />} />
            <Route path="/:id/verify/:token" element={<EmailVerify />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/unsubscribe/:token" element={<Unsubscribe />} />
            <Route
              path="/password-reset/:id/:token"
              element={<PasswordReset />}
            />
            <Route path="*" element={<Error />} />
          </Routes>
        </BrowserRouter>
      </PayPalScriptProvider>
    </React.StrictMode>
  );
}

export default App;
